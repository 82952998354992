:root {
  --magenta: #B81365;
  --orange: #FF9F1C;
  --ash-gray: #BFD3C1;
  --teal: #89BD9E;
  --vista-blue: #7F96FF;
  --charcoal: #2F4858;
}


/* ---------------------------------------------- sm width devices */
@media (max-width: 767.98px) { 
  .nav-link {
    font-size: medium;
  }
  #profile-photo {
    height: 250px;
    width: 250px;
  }
  p.about-text {
    font-size: 15px;
  }
  .project-examples-container{
    display: block;
  }
  #projects {
    padding: 2% 14%;
  }
  .icon {
    height: 60px;
  }
  .skill {
    font-size: xx-small;
    margin: 5px;
  }
}
/* ---------------------------------------------- lg width devices */
@media (min-width: 991.98px) {
  .row {
    max-width: 50vw;    
  }
  .skill {
    margin: 10px;
  }
}
/* ---------------------------------------------- lg height devices */
@media (min-height: 1000px) {
  #intro-component {
    padding-top: 120px;
  }
  .bi-chevron-double-down {
    margin-top: 50px;
  }
  #about {
    padding-bottom: 10px;
  }
  .example {
    margin-bottom: 100px;
  }
}


.App {
  text-align: center;
}

/* ---------------------------------------------- views */
.home-view {
  padding-top: 100px;
}

/* ---------------------------------------------- components */
.about-component, .skills-component, .projects-component {
  padding: 10% 15%;
}

.footer-component {
  padding: 0px;
}

.contact-component {
  align-self: center;
  padding: 5%;
  margin-bottom: 500px;
}

/* ---------------------------------------------- header */
.header-component {
  align-items: center;
}

.navbar {
  background-color: white;
  box-shadow: 0 1px 20px 0px gray;
}

#kb-logo {
  height: 80px;
}

.nav-links {
  text-decoration: none;
  font-size: xx-large;
  color: black;
  align-items: center;
  margin: 10px;
  position: absolute;
}

.nav-link:hover {
  color: var(--teal);
}

.gh-svg:hover, .li-svg:hover {
  fill: var(--teal);
}

/* ---------------------------------------------- intro */
.photo>img {
  border-radius: 50%;
}

/* ---------------------------------------------- about */
.about-text {
  font-size: larger;

}

/* ---------------------------------------------- projects */
.example {
  margin: 20px;
  padding-bottom: 30px;
}

.project {
  margin: 0 auto;
  padding: 10px;
  border: 10px rgb(212, 212, 212) solid;
  border-radius: 10px;
}

.live-project {
  border: 10px var(--charcoal) solid;
}

#crc-ex, #vf-ex, #tv-shows-ex, #tic-tac-toe-ex {
  width: 60vw;
  margin: auto;
}

.icon {
  padding: 10px 15px;
}

/* ---------------------------------------------- skills */
.skill {
  width: 130px;
  height: 50px;
  font-size: large;
  background-color: rgb(65, 65, 65);
  color: white;
  border-radius: 6px;
}


